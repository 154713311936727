import allConf from "./config";
const conf = allConf[process.env.REACT_APP_CONFIG_FILE_NAME] ? allConf[process.env.REACT_APP_CONFIG_FILE_NAME] : allConf.demo;
export const API_URL = {
    GETWAY_API: process.env.REACT_APP_GETWAY_API
}
export const APIV = 2;
export const LINK_URL = {
    LOGO: conf.LOGO,
    GOOGLE_PLAY: conf.GOOGLE_PLAY,
    APP_STORE: conf.APP_STORE,
    WHATSAPP_SHARE: conf.ALLOW_WHATSAPP_SHARE
};
export const DOCUMENT_ID = {
    FIRST_SUBMIT_DEFAULT_DOCUMENT_CATEGORY: 17,
    FIRST_SUBMIT_DEFAULT_DOCUMENT_SUB_CATEGORY: 1,
    DISBURSAL_DEFAULT_DOCUMENT_CATEGORY: 21,
    DISBURSAL_DEFAULT_DOCUMENT_SUB_CATEGORY: 22
};
export const MOBILE_VALIDATION=new RegExp(conf.MOBILE_VALIDATION);
export const FE_STATUS_ID= [6];
export const LOAN_DISBURSE_STATUS_ID= [16];
export const SENT_TO_FINANCIER_STATUS_ID= 7;
export const CURRENCY_SYMBOL= "Rp. ";
export const CURRENCY_SEPARATOR= ".";
export const INCENTIVE_CALCULATION_MENU_ID= 23;
export const FF_STATUS_SUB_MENU= 8;
export const LOAN_APPROVE_DOC_PARENT_ID = 31;
export const ADDITIONAL_DOC_CHILD_ID = [57];
export const FIELD_MAP_WITH_DOCUMENT = {
    'customer_name': 17,
    'mobile_number': 17,
    'bpkb_name': 17,
    'bpkb_ownership': 17,
    'id_card_number': 17,
    'npwp_number': 17,
    'birth_place_id': 17,
    'dob': 17,
    'religion_id': 17,
    'mother_name': 19,
    'marital_status_id': 17,
    'couple_name': 19,
    'couple_id_number': 19,
    'couple_birth_place_id': 19,
    'couple_dob': 19,
    'current_address':17,
    'rt':17,
    'rw':17,
    'state_id':17,
    'city_id':17,
    'locality_id':17,
    'sub_district':17,
    'postal_code':17,
    'c_current_address':17,
    'c_rt':17,
    'c_rw':17,
    'c_state_id':17,
    'c_city_id':17,
    'c_locality_id':17,
    'c_sub_district':17,
    'c_postal_code':17,
    'loan_amount_transferred':31,
    'tenure':31
};
export const CSUL_FINANCER_ID= 6;
export const SINARMAS_FINANCE_ID=7;
export const SUB_STATUS_ID_OF_INCENTIVE_APPROVE = 24;
export const STATUS_ID_OF_LOST = 27;
export const SUB_STATUS_ID_OF_INCENTIVE_APPROVAL_RECEIVED = 37;
export const SUB_STATUS_ID_OF_LOST_PB = 38;
export const ACC_FINANCER_ID= 14;
export const EQUITY_FINANCE_ID= 116;

export const BATAVIA_FINANCE_ID= 8;
export const SMEFI_FINANCE_ID= 22;
export const SINARMAS_HANA_FINANCE_ID= 23;
export const ESTA_DANA_VENTURA_FINANCE_ID= 21;
export const INCENTIVE_STATUS_ID= 21;
export const INCENTIVE_APPROVAL_RECEIVED_SUB_STATUS_ID= 37;
export const ARMADA_FINANCE_ID= 24;
export const KRESNA_FINANCE_ID= 115;
export const SHF_FINANCE_ID= 26;
export const EDITABLE_INCENTIVE_FINANCER_IDS= [
    CSUL_FINANCER_ID,
    SINARMAS_FINANCE_ID,
    EQUITY_FINANCE_ID,
    ACC_FINANCER_ID,
    BATAVIA_FINANCE_ID,
    SMEFI_FINANCE_ID,
    SINARMAS_HANA_FINANCE_ID,
    ESTA_DANA_VENTURA_FINANCE_ID,
    ARMADA_FINANCE_ID,
    KRESNA_FINANCE_ID,
    SHF_FINANCE_ID
];

export const ADMIN_ROLE_ID = 2;
export const ROLE_MANAGEMENT_IDS = {
	LEAD_MANAGEMENT_ROLE_ID: 3,
	DO_MAKER_ROLE_ID: 4,
	DO_CHECKER_ROLE_ID: 5,
    OPS_SUPPORT_ROLE_ID: 6,
	BACKUP_TEAM_ROLE_ID: 15
};

export const MOBILE_VLIDATION_RULE = /^[8]\d{8,11}$/;
export const SEARCH_TYPE_OPTIONS = [
    {
        value: "loan_id",
        label: "Loan ID"
    },
    {
        value: "cust_name",
        label: "Customer Name"
    },
    {
        value: "cust_mobile_number",
        label: "Customer Mobile Number"
    },
    {
        value: "plate_number",
        label: "Plate Number"
    }
]

export const LOAN_BOX_USERS = {
    ROLES:{
        SUPER_ADMIN:{
            ID:'-1',
            FEATURES_REFINANCE_SUB_STATUS: ['all']
        },
        ADMIN:{
            ID:'2',
            FEATURES_REFINANCE_SUB_STATUS: ['all']
        },
        LEAD_MANAGEMENT: {
            ID:'18',
            FEATURES_REFINANCE_SUB_STATUS: [2,3,5,6,36,10,11,12,13,14,16]
        },
        DO_MAKER: {
            ID:'19',
            FEATURES_REFINANCE_SUB_STATUS: [17],
            FEATURES_REFINANCE_ACTIONS: ['ucrf_disbursal_to_customer']
        },
        DO_CHECKER: {
            ID:'20',
            FEATURES_REFINANCE_SUB_STATUS: [19,22]
        },
        OPS_SUPPORT: {
            ID:'22',
            FEATURES_REFINANCE_SUB_STATUS: []
        },
        BACKUP_TEAM: {
            ID:'21',
            FEATURES_REFINANCE_SUB_STATUS: [23,24,37,39]
        },
    }
}

export const OPEN_TAB_REFINANCE_SUB_STATUS_LEAD_MANAGEMENT=[36,10,11,12,13,14,16]
export const OPEN_TAB_REFINANCE_SUB_STATUS_BACKUP_TEAM=[24,37]
export const OPEN_TAB_REFINANCE_SUB_STATUS=[36,10,11,12,13,14,16,24,37,23,25,39]
export const NON_OPEN_TAB_REFINANCE_SUB_STATUS=[2,3,5,6,17,19,22]
export const DISBURSED_TAB_REFINANCE_SUB_STATUS=[20,26]
export const MY_CASE_ROLE_IDS = ['18','19','20']
export const BM_ADVANCE_PAYMENT_LIMIT_DEFAULT_SCHEME_ID = 1;
export const DEALER_ADVANCE_PAYMENT_LIMIT_DEFAULT_SCHEME_ID = 2;
export const TAKE_OVER_REQUIRED_DOC = [46,49,35];

export const FINANCIER_SPECIFIC_DOCS_PARENT_ID = [42, 33]

export const API_TIMEOUT = 15000;

export const AUTH_KEY = "reflnaprv-e1b2519c-4648-4a24-b2dc-86fc7616d24a";
export const SETTING = {
    CRYPTO_KEY: "U*oDwrIU!B#QX37%#24",
    RESEND_OTP_TIME_IN_SECOND: 30,
};

export const REQUEST_RESPONSE_ENCRYPTION = {
    aes_algo: "aes-256-cbc",
    response_aes_key: "770A8A65DA156D24EE2A093277530142",
    public_key: "-----BEGIN PUBLIC KEY-----\nMIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDG2koJfj4vF4UkiSl9vnmznE31\nMV7wslypRbwHEbN0KL4YtpH42euTfTEpD3RuyC3+QG4jHAmtyn+jxQJsZN8/Tq4L\n4N+tp83PZDQoazJDBVDtBCx5Rsyz9P0xemJkbfhbY4LRwQojDYLBlNRI4LHuBnnf\n1sL3PU9daSNQud+GBQIDAQAB\n-----END PUBLIC KEY-----\n",
}
export const KTP_DOC_ID = 17
export const NPWP_DOC_ID = 17
export const STNK_DOC_ID = 20
