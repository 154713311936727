import InterceptorService from "./InterceptorService";
import { API_URL } from "../config/constant";
const BASE_PATH = API_URL.BASE_PATH_PREFIX;
const finaljson={
    ...InterceptorService,
    getFinanceDashboardCard(body) {
        return this.post(`/${BASE_PATH}/lead/lead_dashboard`, body);
    },
    getCustomerLeadDetails(body) {
        return this.post(`/${BASE_PATH}/customer/get_leaddetails`, body);
    },
    getCustomerPersonalDetails(body) {
        return this.post(`/${BASE_PATH}/customer/get_customerinfo`, body);
    },
    updateCustomerLoanDetails(body) {
        return this.post(`/${BASE_PATH}/customer/update_loandetails`, body);
    },
    updateCustomerPersonalDetails(body) {
        return this.post(`/${BASE_PATH}/customer/update_customerinfo`, body);
    },
    updateLoanDetails(body) {
        return this.post(`/${BASE_PATH}/customer/update_lead_details`, body);
    },
    updateVehicleDetails(body) {
        return this.post(`/${BASE_PATH}/customer/update_vehicle_details`, body);
    },
    getResidenceInfo(body) {
        return this.post(`/${BASE_PATH}/customer/get_residenceinfo`, body);
    },
    updateResidentialInfo(body) {
        return this.post(`/${BASE_PATH}/customer/update_residentialinfo`, body);
    },
    getLoanCases(body) {
        return this.post(`/${BASE_PATH}/lead/getleads`, body);
    },
    getConfig(body) {
        return this.post(`/${BASE_PATH}/config/get`, body);
    },
    getLeadDetails(body) {
        return this.post(`/${BASE_PATH}/lead/lead_details`, body);
    },
    getLeadTimeline(body) {
        return this.post(`/${BASE_PATH}/lead/lead_timeline`, body);
    },
    getRefinanceDashboardCard(financeType) {
        return this.post(`/${financeType}/finance/dashboard`);
    },
    getCreateApplicationData(body) {
        return this.post(`/${BASE_PATH}/lead/get_create_application_json`, body);
    },
    updateAssignedLead(body) {
        return this.post(`/${BASE_PATH}/lead/update_assigned_lead`, body);
    },
    getFinancierStatusHistory(body) {
        return this.post(`/${BASE_PATH}/lead/get_financier_lead_history`, body);
    },
    getIFDashboardCard(body){
        return this.post(`/dif/lead/get_lead_count`, body);
    },
    getDifBoosterData(body) {
        return this.post("/loan/lead/get_dif_booster_data", body);
    },
    saveDifBoosterData(body) {
        return this.post("/loan/lead/save_dif_booster_data", body);
    }
};

export default finaljson;